.react-calendar {
    border-color: var(--chakra-colors-gray-300);
}
.react-date-picker__inputGroup__input {
    min-width: 18px;
    text-align: center;
}

.react-date-picker__calendar{
    font-size: 90%;
}
.react-date-picker__wrapper {
    height: var(--chakra-sizes-8);
    border-radius: var(--chakra-radii-sm);
    border: 1px solid;
    border-color: var(--chakra-colors-gray-200);
    padding-left: var(--chakra-space-2);
    flex-grow: 0;
    width: 100%;
}
.react-date-picker {
    width: 100%;
}
.react-date-picker__wrapper:hover {
    border-color: var(--chakra-colors-gray-300);
}
.react-calendar__month-view__weekdays__weekday abbr[title] {
    text-decoration: none;
}
.react-calendar__month-view__days__day--weekend {
    color: var(--chakra-colors-blue-900);
}

.react-calendar__tile--now {
    background: var(--chakra-colors-yellow-100);
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: var(--chakra-colors-blue-500);
    color: white;
}
.react-calendar__tile--active, .react-calendar__tile--hasActive,
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--hasActive:enabled:hover {
    background: var(--chakra-colors-blue-500);
    color: white;
}
.react-calendar__year-view__months__month:hover {
    background: var(--chakra-colors-gray-100);
}


.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 1em 1em
}
.react-date-picker__inputGroup__input, .react-date-picker__inputGroup__divider{
    font-size: var(--chakra-fontSizes-sm)
}

.react-date-picker__clear-button {
    color: var(--chakra-colors-gray-100);
}

.react-date-picker__button__icon{
    stroke: hsl(0, 0%, 80%)
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon, .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: hsl(0, 0%, 60%)
}